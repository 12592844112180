import { Fragment, useState } from 'react';
import {
    Create, SimpleForm, TextInput, useTranslate,
    FormDataConsumer, Toolbar, SaveButton, ReferenceInput,
    Button, AutocompleteInput, useNotify, useRefresh, 
    useGetOne,
} from 'react-admin';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Launch, Send } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle, Box } from '@material-ui/core';
import { HandleError, ViewTitle } from '../../components';
import { Cancel } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    }
}));

const Validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = 'ra.validation.required';
    if (!values.pod) errors.pod = 'ra.validation.required';
    if (!values.instanceTemplate) errors.instanceTemplate = 'ra.validation.required';
    return errors;
};

const DeployInstanceTemplate = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const optionRenderer = choice => `${choice.name} #${choice.snapshotVersion}`;

    const podId = props.pod.id;
    const podName = props.pod.podName;
    const type = props.pod.type;

    const DeployTemplate = async (data) => {

        try {
            const options = {
                headers: {},
            };

            const sendData = {
                pod: data.pod,
                name: data.name,
                description: data.description,
                instanceTemplate: data.instanceTemplate
            };

            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/instanceTemplates/deploy`, sendData, options);
            handleCloseClick();
            if (res.status !== 201) {
                notify(translate('app.instanceTemplates.deploy.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.instanceTemplates.deploy.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        }

    }


    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const postDefaultValue = () => ({
        pod: podName,
        //instanceTemplate: record.id,
        visibility: ['admin', 'designer', 'trainer', 'trainee'],
        //name: podName, description: `Instance from `
    });

    const GetTemplate = (id) => {
        const { data, isLoading, error } = useGetOne('instanceTemplates', { id });
        if (isLoading) { return <div>{translate('app.LoadingMessage')}</div> };
        if (error) { return <div>{translate('app.ErrorMessage')}</div>; }

        const description = "Instance from " + data.name;
        return (
            <>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput variant='outlined' source='name' defaultValue={data.name} fullWidth />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <TextInput variant='outlined' source='description' style={{ minWidth: 250 }}
                            className={classes.inputMarginLeft} defaultValue={description} fullWidth multiline />
                    </Box>
                </Box>
            </>
        );
    };

    //A changer
    const CreateToolbar = (props) => {
        const classes = useStyles();
        const translate = useTranslate();
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.border} >
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.deploy')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    return (
        <Fragment>
            <Button label={translate('app.trainingTemplates.deploy.button')} onClick={handleClick}><Send /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.instanceTemplates.deploy.title')}
            >
                <DialogTitle>
                    <ViewTitle label={translate('app.instanceTemplates.deploy.title')} icon={Launch} />
                </DialogTitle>
                <DialogContent>

                    <Create>
                        <SimpleForm validate={Validate} toolbar={<CreateToolbar />} defaultValues={postDefaultValue}
                            onSubmit={DeployTemplate} >

                            <Box className={classes.flexBox}>
                                <AutocompleteInput source="pod" isRequired choices={[{ id: podId, name: podName }]} optionText="name" />
                            </Box>

                            <Box className={classes.flexBox}>
                                <ReferenceInput
                                    source="instanceTemplate"
                                    reference="instanceTemplates"
                                    label={translate('app.instanceTemplates.deploy.title')}
                                    filter={{ podType: type }}
                                >
                                    <AutocompleteInput optionText={optionRenderer} />
                                </ReferenceInput>

                            </Box>

                            <Box className={classes.flexBox}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => (
                                        formData.instanceTemplate
                                            ? GetTemplate(formData.instanceTemplate)
                                            : null
                                    )}
                                </FormDataConsumer>
                            </Box>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default DeployInstanceTemplate;

